<template>
  <section id="dashboard-ecommerce">
    <b-overlay
      id="overlay-background"
      :show="show"
      variant="primary"
      opacity="0.85"
      blur="2"
      rounded="sm"
    >
      <div>
        <b-card no-body class="mb-0">
          <b-table
            ref="refUserListTable"
            class="position-relative"
            :items="persons"
            responsive
            :fields="tableColumns"
            primary-key="id"
            show-empty
            empty-text="Bu bayi için kişi bulunamadı."
          >
            <template #cell(isActive)="data">
              <b-badge
                v-if="data.item.isActive"
                pill
                :variant="`light-success`"
                class="text-capitalize"
              >
                Aktif
              </b-badge>
              <b-badge
                v-else
                pill
                :variant="`light-warning`"
                class="text-capitalize"
              >
                Pasif
              </b-badge>
            </template>
            <template #cell(emailActivation)="data">
              <b-badge
                v-if="data.item.emailActivation"
                pill
                :variant="`light-success`"
                class="text-capitalize"
              >
                Onaylı
              </b-badge>
              <b-badge
                v-else
                pill
                :variant="`light-warning`"
                class="text-capitalize"
              >
                Beklemede
              </b-badge>
            </template>
            <template #cell(persons)="data">
              <div class="d-flex justify-content-center">
                <b-button
                  variant="primary"
                  class="btn-icon"
                  :to="{
                    name: 'dealers-persons',
                    params: { id: data.item.id }
                  }"
                >
                  <feather-icon icon="UsersIcon" />
                </b-button>
              </div>
            </template>
          </b-table>
          <div class="mx-2 mb-2">
            <b-row>
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
              </b-col>
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >
              </b-col>
            </b-row>
          </div>
        </b-card>
      </div>
    </b-overlay>
  </section>
</template>

<script>
import {
  BFormInput,
  BOverlay,
  BModal,
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BButton,
  BCol,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BImg,
  BFormSpinbutton,
  BDropdownItem,
  BPagination,
} from "bootstrap-vue";
import router from "@/router";

import vSelect from "vue-select";
export default {
  components: {
    BRow,
    BImg,
    BCol,
    BCard,
    BModal,
    BOverlay,
    BFormInput,
    BCardHeader,
    BCardBody,
    BButton,
    BFormSpinbutton,
    vSelect,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
  },
  data() {
    return {
      persons: [],
      tableColumns: [
        { key: "fullName", label: "Adı" },
        { key: "title", label: "Ünvanı" },
        { key: "email", label: "Email" },
      ],
      show: false,
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.show = true;
      this.$http
        .get(`/User/GetDealerContactById?dealerId=${router.currentRoute.params.id}`)
        .then((response) => {
          this.persons = response.data.result;
          this.show = false;
        })
        .catch((error) => {
          this.show = false;

          this.$swal({
            title: "Hata!",
            text: error.response.data.responseException.exceptionMessage,
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            confirmButtonText: "Kapat",
            buttonsStyling: false,
          });
        });
    },
    format(date) {
      var month = date.toLocaleString("tr-TR", { month: "short" });
      return date.getDate() + " " + month + " " + date.getFullYear();
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
</style>
